import React, { useState } from "react";
import { Image, Nav, Navbar, NavLink } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import EnBWLogo from "../assets/EnBW_logo.svg";
import { useIntl } from 'react-intl'
import AuthorizeModal from "./AuthorizeModal";
import { useDispatch, useSelector } from "react-redux";
import { updateLogin } from "../redux/actions";
import { removeLoginToken, setLanguage } from "../utils";

interface ICustomNavbarProps {
  setActiveLanguage: (code: "en" | "de") => void;
  activeLanguage: "en" | "de";
}

const CustomNavbar = ({
  setActiveLanguage,
  activeLanguage,
}: ICustomNavbarProps) => {
  const { formatMessage } = useIntl();

  // @ts-ignore
  const auth = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const history = useHistory();
  const [showAuthorizeModal, setShowAuthorizeModal] = useState(false);

  const handleLogout = () => {
    dispatch(updateLogin(false));
    removeLoginToken();
    history.replace("/");
  };

  const handleLanguageChange = (code: "en" | "de") => {
    setActiveLanguage(code);
    setLanguage(code);
  };

  return (
    <Navbar className="navbar-enbw" expand="lg" fixed={"top"}>
      <Navbar.Brand href="/">
        <Image src={EnBWLogo} className="logo" />
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="enbw-navbar-nav" />
      <Navbar.Collapse id="enbw-navbar-nav">
        <Nav className="ml-auto">
          {process.env.REACT_APP_TENANT !== "enkk" ? (
            <NavLink href="/" className="nav-link nav-link-enbw">
              {formatMessage({ id: "about-us" })}
            </NavLink>
          ) : null}
          {auth ? (
            <>
              <NavLink href="/dashboard" className="nav-link-enbw">
                {formatMessage({ id: "your-account" })}
              </NavLink>
              <NavLink className="nav-link-enbw" onClick={handleLogout}>
                {formatMessage({ id: "logout" })}
              </NavLink>
            </>
          ) : (
            <NavLink
              className="nav-link-enbw"
              onClick={() => setShowAuthorizeModal(true)}
            >
              {formatMessage({ id: "authorize" })}
            </NavLink>
          )}
          <NavLink
            className={`nav-link-enbw ${activeLanguage === "en" ? "font-weight-medium" : ""
              }`}
            onClick={() => handleLanguageChange("en")}
          >
            EN
          </NavLink>
          <div className="divider" />
          <NavLink
            className={`nav-link-enbw ${activeLanguage === "de" ? "font-weight-medium" : ""
              }`}
            onClick={() => handleLanguageChange("de")}
          >
            DE
          </NavLink>
        </Nav>
      </Navbar.Collapse>
      <AuthorizeModal
        show={showAuthorizeModal}
        onHide={() => setShowAuthorizeModal(false)}
        activeLanguage={activeLanguage}
      />
    </Navbar>
  );
};

export default CustomNavbar;
