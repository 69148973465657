import React from 'react'
import { useIntl } from 'react-intl'


const Footer = () => {
  const { formatMessage } = useIntl();

  return (
    <section className="footer">
      <div className="mb-3">
        <a href="https://www.enbw.com/service/datenschutz/" className="footer-link-enbw" target="_blank" rel="noopener noreferrer">
          {formatMessage({ id: "privacy-policy" })}
        </a>
        <a href="https://www.enbw.com/impressum/" className="footer-link-enbw" target="_blank" rel="noopener noreferrer">
          {formatMessage({ id: "imprint" })}
        </a>
        <a href="https://www.enbw.com/disclaimer/" className="footer-link-enbw" target="_blank" rel="noopener noreferrer">
          {formatMessage({ id: "disclaimer" })}
        </a>
      </div>
      <div>
        <span>© {formatMessage({ id: "footer-rights" })}</span>
      </div>
    </section>
  )
}

export default Footer