import { UPDATE_USER } from "../actions";
import { IUserDetails } from '../../pages/MagicLink'

const initialState: IUserDetails | null = null;

const user = (state = initialState, action: any) => {
  switch (action.type) {
    case UPDATE_USER: {
      return action.payload;
    }
    default: {
      return state;
    }
  }
};

export default user;
