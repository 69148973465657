import React from 'react'
import { Spinner } from 'react-bootstrap'

interface ILoadingProps {
  show: boolean
}

const Loading = ({ show }: ILoadingProps) => {
  if(show) {
    return (
      <Spinner animation="border" className="loading" />
    )
  }
  return null
}

export default Loading