import React, { useState } from 'react'
import { Col } from 'react-bootstrap'
import { IFaq } from './Faq'

interface IFaqItemProps {
  faq: IFaq
}

const FaqItem = ({ faq }: IFaqItemProps) => {
  const [answerOpened, setAnswerOpened] = useState(false)

  const toggleAnswerOpened = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    event.preventDefault()
    setAnswerOpened(prevState => !prevState)
  }

  return (
    <Col md={6} className="mb-4">
      <div className="faq-item-container">
        <div className="faq-item" onClick={toggleAnswerOpened}>
          <span className="font-weight-medium">{faq.question}</span>
          <button className="btn btn-enbw-round">
            {answerOpened ? "-" : "+"}
          </button>
        </div>
        {
          answerOpened &&
          <div>
            <p dangerouslySetInnerHTML={{__html:faq.answer}} />
          </div>
        }
      </div>

    </Col>
  )
}

export default FaqItem