import React from "react";
import { useIntl } from 'react-intl'
import { Image } from "react-bootstrap";
import DownloadIcon from "../assets/icons/icon_download.svg";
import { toast } from "react-toastify";
import type { IAsset } from "../types/assets";


interface IAssetItemProps {
  className?: string;
  showWarning?: boolean;
  activeLanguage: string;
  asset: IAsset;
}

const AssetItem = (props: IAssetItemProps) => {
  const { formatMessage } = useIntl();

  const { className, activeLanguage, asset } = props;


  const getLink = () => {
    if (asset === undefined) {
      return "";
    }

    if (activeLanguage === "en") {
      return asset.file_url_en;
    } else {
      return asset.file_url_de;
    }
  };

  const getFileName = () => {
    return getLink().split("/").pop();
  }

  const getText = () => {
    if (asset === undefined) {
      return "";
    }

    if (activeLanguage === "en") {
      return asset.text_en;
    } else {
      return asset.text_de;
    }
  }

  const isEnkk = () => {
    return process.env.REACT_APP_TENANT === "enkk";
  };

  const handleShowWarning = () => {
    if (props.showWarning && !isEnkk()) {
      toast.info(formatMessage({ id: "subsidiary-download-warning" }), {
        autoClose: false,
      });
    }
  };


  return (
    <div className={`download-item ${className ? className : ""}`}>
      <span>
        {getText()}
      </span>
      <a
        href={getLink()}
        target="_blank"
        rel="noreferrer"
        download={getFileName()}
        onClick={handleShowWarning}
      >
        <Image src={DownloadIcon} />
      </a>
    </div>
  );
};

export default AssetItem;
