import axios, { AxiosResponse } from "axios";
import { getLoginToken, removeLoginToken } from "../utils";
import { updateLogin } from "../redux/actions";
import store from "../redux/store";

const BASE_URL = process.env.REACT_APP_BACKEND_API;

const axiosInstance = axios.create({
  baseURL: BASE_URL,
});

const axiosAuthenticatedInstance = axios.create({
  baseURL: BASE_URL,
  headers: {
    Authorization: `JWT ${getLoginToken()}`,
  },
});

axiosAuthenticatedInstance.interceptors.request.use(
  (config) => {
    const token = getLoginToken();
    if (token) {
      config.headers.Authorization = `JWT ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

export const handleApiError = (error: any) => {
  let errorMessage: string = "Something went wrong";
  if (error.response) {
    // The request was made and the server responded with a status code
    // that falls out of the range of 2xx
    if (error.response.status === 403) {
      store.dispatch(updateLogin(false));
      removeLoginToken();
      window.location.href = "/";
    }
    errorMessage = `${error.response.data.error}`;
  } else if (error.request) {
    // The request was made but no response was received
    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
    // http.ClientRequest in node.js
    errorMessage = `No Response received: ${error.request.responseText}`;
  } else {
    // Something happened in setting up the request that triggered an Error
    errorMessage = `Request failed: ${error.message}`;
  }
  return errorMessage;
};

export const fetchMaintenance = async () => {
  // maintenance is enabled if status code IS NOT 200
  try {
    const result = await axiosAuthenticatedInstance.get("/maintenance");
    return result.status !== 200;
  } catch (_) {
    return true;
  }
};

export const sendMagicLink = (email: string, language: string) => {
  return axiosInstance.post("/send_magic_link", { email, language });
};

export const sendCompanyEmail = (data: any) => {
  return axiosInstance.post("/send_company_email", data);
};

export const fetchAssets = () => {
  return axiosInstance.get("/assets");
};

export const acquireJWT = (magic_token: string) => {
  return axiosInstance
    .post("/acquire_jwt", { magic_token })
    .then(function (response: AxiosResponse<any>) {
      const { data } = response;
      return data;
    })
    .catch(function (error) {
      return undefined;
    });
};

export const fetchUserDetails = () => {
  return axiosAuthenticatedInstance.get("/me");
};

export const fetchUsers = () => {
  return axiosAuthenticatedInstance.get("/users");
};

export const updateUserDetails = (data: any) => {
  return axiosAuthenticatedInstance.patch("/me", data);
};

export const addNewEmail = (email_address: string) => {
  return axiosAuthenticatedInstance.post("/add_email", { email_address });
};

export const deleteEmail = (email_address: string) => {
  return axiosAuthenticatedInstance.delete("/users", {
    data: { email_address },
  });
};

