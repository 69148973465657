import React, { useState } from 'react'
import { useIntl } from 'react-intl'
import { Form } from 'react-bootstrap'
import { useHistory } from 'react-router-dom'
import Assets from '../components/Assets'
import { LocationEnum } from "../types/assets";

interface ISupplierAgreementProps {
  activeLanguage: "en" | "de";
}

const SupplierAgreement = ({ activeLanguage }: ISupplierAgreementProps) => {
  const { formatMessage } = useIntl();

  const history = useHistory();
  const [supplierAgreementApproved, setSupplierAgreementApproved] = useState(false)

  const handleFormCheck = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSupplierAgreementApproved(event.target.checked)
  }

  return (
    <div className="page-container">
      <h1 className="font-weight-bold mb-5">
        {formatMessage({ id: "step-title" })}
      </h1>
      <p>
        {formatMessage({ id: "step-1" })}
      </p>
      <Assets activeLanguage={activeLanguage} location={LocationEnum.Dashboard} className="w-25 mt-2 mb-4" />
      <div>
        <Form.Check
          type="checkbox"
          id="supplier-agreement-checked"
          label={formatMessage({ id: 'confirm-supplier-agreement' })}
          checked={supplierAgreementApproved}
          onChange={handleFormCheck}
        />
      </div>
      <div className="mt-5">
        <button className="btn btn-enbw" disabled={!supplierAgreementApproved} onClick={() => history.push('invoice-channel')}>
          {formatMessage({ id: "continue" })}
        </button>
      </div>
    </div>
  )
}

export default SupplierAgreement