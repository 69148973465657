import React from 'react'
import { Image } from 'react-bootstrap'


interface IInvoiceItemProps {
  title: string;
  iconSrc: string;
  selected: boolean;
  onClick: () => void;
}

const InvoiceItem = ({title, iconSrc, selected, onClick}: IInvoiceItemProps) => {
  return (
    <div className={`invoice-item-square m-3 ${selected ? 'selected': ''}`} onClick={onClick}>
      <p className="font-weight-bold">{title}</p>
      <div className="text-center">
        <Image src={iconSrc} />
      </div>
    </div>
  )
}

export default InvoiceItem