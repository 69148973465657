import React, { useState } from 'react'
import { Col, Image, Row } from 'react-bootstrap'
import IconTrash from '../assets/icons/icon_trash.svg'
import IconYes from '../assets/icons/icon_yes.svg'
import IconNo from '../assets/icons/icon_no.svg'
import { IUserDetails } from '../pages/MagicLink'
import moment from 'moment'
import ConfirmDelete from './ConfirmDelete'


interface IUserListItemProps {
  user: IUserDetails,
  handleEmailDelete: (email: string) => void
}

const UserListItem = ({ user, handleEmailDelete }: IUserListItemProps) => {
  const [showConfirmDelete, setShowConfirmDelete] = useState(false)

  const toggleConfirmDelete = () => {
    setShowConfirmDelete(prevState => !prevState)
  }

  const getDateString = (dateString: string) => {
    return moment(dateString).format('DD-MM-YYYY')
  }

  return (
    <>
      <Row className="dashboard-email-row">
        <Col xs={5}>{user.email_address}</Col>
        <Col xs={3}>{getDateString(user.added_date)}</Col>
        <Col xs={2} className='pl-4'>
          <Image src={user.terms_accepted ? IconYes : IconNo} style={{ height: "17px", width: "17px" }} />
        </Col>
        <Col xs={2} className="text-right">
          <Image
            src={IconTrash}
            onClick={toggleConfirmDelete}
            className='icon-button'
          />
        </Col>
      </Row>
      <ConfirmDelete
        email={user.email_address}
        show={showConfirmDelete}
        onHide={toggleConfirmDelete}
        handleEmailDelete={handleEmailDelete}
      />
    </>
  )
}

export default UserListItem