import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import "./App.css";
import CustomNavbar from "./components/CustomNavbar";
import Home from "./pages/Home";
import globalTranslations from "./translations.json";
import enbwTranslations from "./enbw-translations.json";
import enkkTranslations from "./enkk-translations.json";
import MagicLink from "./pages/MagicLink";
import SupplierAgreement from "./pages/SupplierAgreement";
import InvoiceChannel from "./pages/InvoiceChannel";
import Dashboard from "./pages/Dashboard";
import ProtectedRoute from "./components/ProtectedRoute";
import Footer from "./components/Footer";
import IEWarning from "./components/IEWarning";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getLanguage } from "./utils";
import { fetchMaintenance } from "./api/helpers";
import MaintenanceModal from "./components/MaintenanceModal";
import { Helmet } from "react-helmet";
import { IntlProvider } from 'react-intl'

const App = () => {
  const [isMaintenance, setMaintenance] = useState(false);
  const [translations, setTranslations] = useState({ "en": {}, "de": {} })
  const [activeLanguage, setActiveLanguage] = useState<"en" | "de">("de")
  useEffect(() => {
    fetchMaintenance().then((_isMaintenance) => {
      setMaintenance(_isMaintenance);
    });

    let translations: any = { ...globalTranslations };
    if (process.env.REACT_APP_TENANT === "enkk") {
      translations = { ...translations, ...enkkTranslations };
    } else {
      translations = { ...translations, ...enbwTranslations };
    }
    const enTranslations: any = {}
    const deTranslations: any = {}
    Object.keys(translations).forEach(key => {
      enTranslations[key] = translations[key][0]
      deTranslations[key] = translations[key][1]
    })
    setTranslations({ "en": enTranslations, "de": deTranslations })

    const browserLanguage = getLanguage() || navigator.language.slice(0, 2);
    if (["en", "de"].includes(browserLanguage)) {
      setActiveLanguage(browserLanguage as "en" | "de");
    } else {
      setActiveLanguage("de");
    }
  }, [])

  const isIE = () => {
    const userAgent = navigator.userAgent;
    /* MSIE used to detect old browsers and Trident used to newer ones*/
    return (
      userAgent.indexOf("MSIE ") > -1 || userAgent.indexOf("Trident/") > -1
    );
  };

  return (
    <div className="App">
      <IntlProvider messages={translations[activeLanguage]} locale={activeLanguage}>
        <Helmet>
          <title>
            {process.env.REACT_APP_TENANT === "enkk"
              ? "EnKK Elektronischer Rechnungseingang"
              : "EnBW Elektronischer Rechnungseingang"}
          </title>
        </Helmet>
        <Router>
          <ToastContainer />
          <CustomNavbar activeLanguage={activeLanguage} setActiveLanguage={setActiveLanguage} />
          <div className="main-container">
            {isIE() && <IEWarning />}
            <MaintenanceModal show={isMaintenance} />
            <Switch>
              <Route exact path="/">
                <Home activeLanguage={activeLanguage} />
              </Route>
              <Route exact path="/magic_link/:token">
                <MagicLink />
              </Route>
              <ProtectedRoute exact path="/supplier-agreement">
                <SupplierAgreement activeLanguage={activeLanguage} />
              </ProtectedRoute>
              <ProtectedRoute exact path="/invoice-channel">
                <InvoiceChannel />
              </ProtectedRoute>
              <ProtectedRoute exact path="/dashboard">
                <Dashboard activeLanguage={activeLanguage} />
              </ProtectedRoute>
              <Route path="*">
                <Home activeLanguage={activeLanguage} />
              </Route>
            </Switch>
          </div>
          <Footer />
        </Router>
      </IntlProvider>
    </div>
  );
};

export default App;
