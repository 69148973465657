import React from 'react'
import { useIntl } from 'react-intl'


const IEWarning = () => {
    const { formatMessage } = useIntl();

    return (
        <div className="ie-warning">
            {formatMessage({ id: "ie-warning" })}
        </div>
    )
}

export default IEWarning