const TOKEN_KEY = 'EnBWToken'
const LANGUAGE_KEY = 'EnBWLang'


export const setLoginToken= (token: string) => {
  localStorage.setItem(TOKEN_KEY, token)
}

export const getLoginToken= () => {
  return localStorage.getItem(TOKEN_KEY)
}

export const removeLoginToken= () => {
  localStorage.removeItem(TOKEN_KEY)
}


export const isLoggedIn = () => {
  return !!localStorage.getItem(TOKEN_KEY)
}

export const setLanguage= (code: 'en' | 'de') => {
  localStorage.setItem(LANGUAGE_KEY, code)
}

export const getLanguage= () => {
  return localStorage.getItem(LANGUAGE_KEY)
}