import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { acquireJWT } from '../api/helpers'
import { useIntl } from 'react-intl'
import ErrorIcon from '../assets/icons/icon_error_page.svg'
import { Image } from 'react-bootstrap'
import { removeLoginToken, setLoginToken } from '../utils'
import { useDispatch } from 'react-redux'
import { updateLogin } from '../redux/actions'

export interface IUserDetails {
  added_date: string
  company_name?: string
  contact_email_address?: string
  email_address: string
  invoice_type?: string
  supplier_num: string
  terms_accepted: boolean
}


const MagicLink = () => {
  const { formatMessage } = useIntl();

  const { token } = useParams<{ token: string }>();
  const history = useHistory()
  const dispatch = useDispatch()
  const [showError, setShowError] = useState(false)

  useEffect(() => {
    const attemptLogin = async () => {
      const loginRes = await acquireJWT(token)
      if (loginRes) {
        const { token } = loginRes
        setLoginToken(token)
        dispatch(updateLogin(true))
        history.replace('/dashboard')
      } else {
        setShowError(true)
      }
    }
    dispatch(updateLogin(false))
    removeLoginToken()
    attemptLogin()
    // eslint-disable-next-line
  }, [token])

  return (
    <div className="page-container">
      {
        showError &&
        <>
          <h2 className="magic-link-error">
            {formatMessage({ id: "magic-link-error" })}
          </h2>
          <Image src={ErrorIcon} />
        </>
      }
    </div>
  )
}

export default MagicLink