import React, { useState, useRef } from "react";
import { Modal, ModalProps, Form } from "react-bootstrap";
import { sendCompanyEmail, sendMagicLink } from "../api/helpers";
import { AxiosResponse } from "axios";
import BrowserWarning from "./BrowserWarning";
import ReCaptchaV2 from "react-google-recaptcha";
import { useIntl } from 'react-intl'

interface IAuthorizeModalProps extends ModalProps {
  onHide: () => void;
  show: boolean;
  activeLanguage: "en" | "de";
}

type RegisterData = {
  company_name: string;
  email: string;
  supplier_number: string;
  language: string;
  "g-recaptcha-response"?: string;
};

const RETRY_TIMEOUT_SEC = 120

const AuthorizeModal = ({
  onHide,
  show,
  activeLanguage,
  ...props
}: IAuthorizeModalProps) => {
  const { formatMessage } = useIntl();

  const [email, setEmail] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [supplierNum, setSupplierNum] = useState("");
  const [showEmailSent, setShowEmailSent] = useState(false);
  const [showVerifySent, setShowVerifySent] = useState(false);
  const [showNotRegistered, setShowNotRegistered] = useState(false);
  const [showCommonError, setShowCommonError] = useState(false);
  const recaptchaRef = useRef<any>();
  const [timer, setTimer] = useState(0);
  const [intervalCallback, setIntervalCallback] = useState<any>(null);

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (showNotRegistered && companyName) {
      handleRegisterWithCaptcha();
    } else {
      handleAuthorize();
    }
  };

  const handleAuthorize = () => {
    sendMagicLink(email, activeLanguage)
      .then(function (response: AxiosResponse<any>) {
        setShowEmailSent(true);
        handleDisabledResend();
      })
      .catch(function (errorResponse) {
        if (
          errorResponse.response?.status === 400 &&
          errorResponse.response?.data?.error === "User not found"
        ) {
          setShowNotRegistered(true);
        } else {
          setShowCommonError(true);
        }
      });
  };

  const handleDisabledResend = () => {
    setTimer(RETRY_TIMEOUT_SEC);

    // Start the countdown timer
    const interval = setInterval(() => {
      setTimer(prevTimer => prevTimer - 1);
    }, 1000);

    setIntervalCallback(interval);

    // Set a timeout to re-enable the button after 2 minutes
    setTimeout(() => {
      clearInterval(interval);
    }, RETRY_TIMEOUT_SEC * 1000); // seconds to milliseconds
  }


  const handleRegister = (data: RegisterData) => {
    sendCompanyEmail(data)
      .then(function (response: AxiosResponse<any>) {
        setShowVerifySent(true);
      })
      .catch(function (errorResponse) {
        setShowCommonError(true);
      });
  };

  const handleRegisterWithCaptcha = async () => {
    const data: RegisterData = {
      company_name: companyName,
      email: email,
      supplier_number: supplierNum,
      language: activeLanguage,
    };
    if (recaptchaRef && recaptchaRef.current) {
      recaptchaRef.current
        .executeAsync()
        .then((token: any) => {
          data["g-recaptcha-response"] = token;
          handleRegister(data);
        })
        .catch((e: any) => {
          console.log(e);
        });
    } else {
      handleRegister(data);
    }
  };

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
    setShowEmailSent(false);
    setShowCommonError(false);
    setShowNotRegistered(false);
    setShowVerifySent(false);
  };

  const handleCompanyNameChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setCompanyName(event.target.value);
    setShowEmailSent(false);
    setShowCommonError(false);
    setShowVerifySent(false);
  };
  const handleSupplierNumChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setSupplierNum(event.target.value);
    setShowEmailSent(false);
    setShowCommonError(false);
    setShowVerifySent(false);
  };

  const handleClose = () => {
    setEmail("");
    setShowEmailSent(false);
    setShowCommonError(false);
    setShowNotRegistered(false);
    setShowVerifySent(false);
    setTimer(0)
    if (intervalCallback) {
      clearInterval(intervalCallback);
    }

    onHide();
  };
  const goToFaq = () => {
    window.location.hash = "#faq";
    onHide();
  };

  const getButtonText = () => {
    if (showEmailSent) {
      if (timer > 0) {
        return "re-send-in"
      }
      return "re-send";
    } else if (showNotRegistered) {
      return "verify";
    }
    return "authorize";
  };

  return (
    <Modal
      show={show}
      size="sm"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      onHide={handleClose}
    >
      <Modal.Header closeButton />
      <form onSubmit={handleSubmit}>
        <Modal.Body>
          {!showNotRegistered ? (
            <>
              <h3>
                {formatMessage({ id: "authorize" })}
              </h3>
              <p>
                {formatMessage({ id: "authorize-description" })}
              </p>
              <Form.Control
                type="email"
                placeholder="Email"
                value={email}
                onChange={handleEmailChange}
                className="input-enbw mb-2"
                autoComplete="off"
                required
              />
            </>
          ) : (
            <>
              <h3>
                {formatMessage({ id: "verify-as-supplier" })}
              </h3>
              <p>
                {formatMessage({ id: "verify-supplier-description" })}
              </p>
              <p className="enbw-text-warning">
                {formatMessage({ id: "verify-supplier-warning" })}
              </p>
              <Form.Control
                placeholder={formatMessage({ id: "company-name" }) as string}
                value={companyName}
                onChange={handleCompanyNameChange}
                className="input-enbw mb-2"
                autoComplete="off"
                required
              />
              <Form.Control
                placeholder={formatMessage({ id: "supplier-num" }) as string}
                value={supplierNum}
                onChange={handleSupplierNumChange}
                className="input-enbw mb-2"
                autoComplete="off"
              />
              <ReCaptchaV2
                sitekey={process.env.REACT_APP_CAPTCHA_KEY || ""}
                size="invisible"
                ref={recaptchaRef}
              />
            </>
          )}

          {showEmailSent && (
            <p>
              {formatMessage({ id: "email-sent" })}
            </p>
          )}
          {showVerifySent && (
            <p className="pb-4">
              {formatMessage({ id: "verify-sent" })}
            </p>
          )}
          {showCommonError && (
            <p>
              {formatMessage({ id: "email-sent-error" })}
            </p>
          )}
          <br />
          <p>
            {formatMessage({ id: "need-help-go-to-faq" })}
            <a href="#faq" title="FAQ" onClick={goToFaq}>
              FAQs
            </a>
          </p>
        </Modal.Body>
        {!showVerifySent && (
          <Modal.Footer>
            <button type="submit" className="btn btn-enbw" disabled={timer > 0}>
              {formatMessage({ id: getButtonText() })} {timer > 0 && `${timer} ${formatMessage({ id: "seconds" })}`}
            </button>
          </Modal.Footer>
        )}

        <BrowserWarning />
      </form>
    </Modal>
  );
};

export default AuthorizeModal;
