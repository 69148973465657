import { IUserDetails } from '../pages/MagicLink'

export const UPDATE_LOGIN = "UPDATE_LOGIN"
export const UPDATE_USER = "UPDATE_USER"


export const updateLogin = (status: boolean) => ({
  type: UPDATE_LOGIN,
  payload: status
});


export const updateUser = (user: IUserDetails) => ({
  type: UPDATE_USER,
  payload: user
});