import React from "react";
import AssetItem from "./AssetItem";
import { useAssets } from "../hooks/assets";
import { LocationEnum } from "../types/assets";

interface IAssetsProps {
    className?: string;
    activeLanguage: string;
    location: LocationEnum;
}

const Assets = (props: IAssetsProps) => {
    const assets = useAssets(props.location);

    return (
        <>
            {assets && assets.map((asset) => (
                <AssetItem
                    key={asset.text_en}
                    asset={asset}
                    className={props.className}
                    showWarning={asset.show_in_user_dashboard}
                    activeLanguage={props.activeLanguage}
                />
            ))}
        </>
    );
};

export default Assets;
