import { UPDATE_LOGIN } from "../actions";
import { isLoggedIn } from '../../utils'

const initialState: boolean = isLoggedIn();

const auth = (state = initialState, action: any) => {
  switch (action.type) {
    case UPDATE_LOGIN: {
      return action.payload;
    }
    default: {
      return state;
    }
  }
};

export default auth;