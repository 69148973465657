import React from "react";
import { isChrome, isFirefox } from "react-device-detect";
import { useIntl } from 'react-intl'

export default function BrowserWarning() {
  const { formatMessage } = useIntl();

  if (isChrome || isFirefox) {
    return null;
  }

  return (
    <div className="browser-warning-container">
      <p>
        {formatMessage({ id: "browser-warning" })}
      </p>
    </div>
  );
}
