import React from 'react'
import { Col, Image } from 'react-bootstrap'
import { useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'



interface IProcesItemProps {
  itemNo: number;
  title: string;
  subtitle: string;
  iconSrc: string;
  btnTitle?: string;
  handleAuthorizeModal: (show: boolean) => void;
}

const ProcessItem = ({ itemNo, title, subtitle, iconSrc, btnTitle, handleAuthorizeModal }: IProcesItemProps) => {
  const { formatMessage } = useIntl();

  // @ts-ignore
  const auth = useSelector(state => state.auth)

  const history = useHistory()
  return (
    <Col xl={4}>
      <div className="process-item">
        <Col xs={2} className="process-number">
          {itemNo}
        </Col>
        <Col xs={10} className="process-content">
          <div>
            <p className="font-weight-bold">{title}</p>
            <p>{subtitle}</p>
          </div>
          {
            btnTitle &&
            <div className="banner-button-container">
              {
                auth ?
                  <button className="btn btn-enbw" onClick={() => history.push('/dashboard')}>
                    {formatMessage({ id: "your-account" })}
                  </button> :
                  <button className="btn btn-enbw" onClick={() => handleAuthorizeModal(true)}>
                    {formatMessage({ id: btnTitle })}
                  </button>
              }
            </div>
          }
        </Col>
        <div className="process-icon-container">
          <Image src={iconSrc} className="process-icon" />
        </div>
      </div>
    </Col>
  )
}

export default ProcessItem