import React from "react";
import { Modal, ModalProps } from "react-bootstrap";
import { useIntl } from 'react-intl'


const MaintenanceModal = ({
  onHide = () => { },
  show,
}: ModalProps) => {
  const { formatMessage } = useIntl();

  return (
    <Modal
      show={show}
      size="sm"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      onHide={onHide}
    >
      <Modal.Header>
        <p></p>
      </Modal.Header>
      <Modal.Body>
        <h3>
          {formatMessage({ id: "maintenance-title" })}
        </h3>
        <p>
          {formatMessage({ id: "maintenance-description" })}
        </p>
      </Modal.Body>
      <Modal.Footer>
        <p></p>
      </Modal.Footer>
    </Modal>
  );
};

export default MaintenanceModal;
