import { useEffect, useState } from "react";
import { fetchAssets } from "../api/helpers";
import { AxiosResponse } from "axios";
import { IAsset, LocationEnum } from "../types/assets";


export const useAssets = (location: LocationEnum) => {
    const [assets, setAssets] = useState<IAsset[]>()
    useEffect(() => {
        fetchAssets()
            .then(function (response: AxiosResponse<any>) {
                let results: IAsset[] = response.data.results

                if (location === LocationEnum.Dashboard) {
                    results = results.filter((asset: IAsset) => {
                        return asset.show_in_user_dashboard
                    })
                }

                setAssets(results)
            })
            .catch(function (errorResponse) {
                console.log(errorResponse)
            });
    }, [location])

    return assets
}