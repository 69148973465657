export interface IAsset {
    file_url_en: string;
    file_url_de: string;
    text_en: string;
    text_de: string;
    show_in_user_dashboard: boolean;
}

export enum LocationEnum {
    Home = "Home",
    Dashboard = "Dashboard",
}
