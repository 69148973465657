import React from "react";
import { Col } from "react-bootstrap";

interface IInterestYouItemProps {
  title: string;
  subtitle: string;
  bgSrc: string;
  href: string;
}

const InterestYouItem = ({
  title,
  subtitle,
  bgSrc,
  href,
}: IInterestYouItemProps) => {
  return (
    <Col md={4} className="interest-you-item-container">
      <div className="interest-you-item">
        <div
          className="interest-you-item-background"
          style={{ backgroundImage: `url(${bgSrc})` }}
        ></div>
        <div className="interest-you-inner-container">
          <a
            href={href}
            className="image-link h-100"
            target="_blank"
            rel="noopener noreferrer"
          >
            <p className="interest-you-item-title">{title}</p>
          </a>
        </div>
      </div>
    </Col>
  );
};

export default InterestYouItem;
