import React from 'react'
import { Redirect, Route, RouteProps } from 'react-router-dom'
import { isLoggedIn } from '../utils'


const ProtectedRoute = ({ children, ...rest }: RouteProps) => {
  return (
    <Route
      {...rest}
      render={({ location }) =>
        isLoggedIn() ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/"
            }}
          />
        )
      }
    />
  );
}

export default ProtectedRoute